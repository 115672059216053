.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.ButtonRed {
    background-color: red;
    color: white;
    font-size: 20px;
    width: 180px;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
}

.ButtonGreen {
  background-color: green;
  color: white;
  font-size: 20px;
  width: 180px;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

